import type firebase from 'firebase/app';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useLatest, useMountedState } from 'react-use';

import { type FirebaseSafeRead } from '@lp-lib/firebase-typesafe';
import { LogLevel } from '@lp-lib/logger-base';

import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useInstance } from '../../hooks/useInstance';
import { useIsMounted } from '../../hooks/useIsMounted';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import { Emitter, type EmitterListener } from '../../utils/emitter';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { type FirebaseService } from './service';
import { log } from './shared';
import {
  type FirebaseEvents,
  FirebaseStatus,
  type NarrowedWebDatabaseReference,
  type RecoveryConfig,
} from './types';

export const AutoRecoveryDelayOptions = [
  0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000, 4000, 5000,
  6000, 7000, 8000, 9000, 10000, 15000, 20000, 30000, 60000,
] as const;

export type AutoRecoveryDelay = (typeof AutoRecoveryDelayOptions)[number];

interface FirebaseContext {
  svc: FirebaseService;
  status: FirebaseStatus;
  autoRecoveryEnabled: boolean;
  setAutoRecoveryEnabled: (enabled: boolean) => void;
  autoRecoveryDelay: number;
  setAutoRecoveryDelay: (delay: AutoRecoveryDelay) => void;
  init: () => Promise<void>;
  dispose: () => Promise<void>;
  emitter: EmitterListener<FirebaseEvents>;
}

const Context = React.createContext<FirebaseContext | null>(null);

export const useFirebaseContext = (): FirebaseContext => {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('No value for FirebaseContext');
  return ctx;
};

export const useOptionalFirebaseContext = (): FirebaseContext | null => {
  const ctx = useContext(Context);
  return ctx;
};

export const useFirebaseDatabase = (): firebase.database.Database => {
  const ctx = useFirebaseContext();
  return ctx.svc.database();
};

/**
 * @deprecated Please use useDatabaseSafeRef instead.
 */
export const useDatabaseRef = <T,>(
  path: string,
  prefix = true
): NarrowedWebDatabaseReference<T> => {
  const ctx = useFirebaseContext();
  const ref = useMemo(
    () => (prefix ? ctx.svc.prefixedRef<T>(path) : ctx.svc.ref<T>(path)),
    [ctx.svc, path, prefix]
  );
  return ref;
};

/**
 * Given a string path and a matching T structure, provide a Firebase RTDB ref
 * that is fully typed and prefixed according to the current environment.
 */
export const useDatabaseSafeRef = <T,>(
  path: string,
  prefix = true
): NarrowedWebDatabaseReference<FirebaseSafeRead<T>> => {
  const ctx = useFirebaseContext();
  const ref = useMemo(
    () =>
      prefix ? ctx.svc.prefixedSafeRef<T>(path) : ctx.svc.safeRef<T>(path),
    [ctx.svc, path, prefix]
  );
  return ref;
};

/**
 * @deprecated Prefix-less firebase refs are discouraged. Please migrate to
 * useDatabaseSafeRef.
 */
export const useOptionalDatabaseRef = <T,>(
  path: string,
  prefix = true
): NarrowedWebDatabaseReference<T> | undefined => {
  const ctx = useOptionalFirebaseContext();
  const svc = ctx?.svc;
  const ref = useMemo(() => {
    if (!svc) return undefined;
    return prefix ? svc.prefixedRef<T>(path) : svc.ref<T>(path);
  }, [svc, path, prefix]);
  return ref;
};

/**
 * Return the firebase value at `path` and provide a writable interface using
 * react hook lifecycles. seedValue is only used if the initial response from
 * the database is empty/null. The initial return value will be `undefined`
 * until a response is received from firebase. It will then be either `null` or
 * the expected value.
 */
export function useFirebaseValue<T>(
  path: string,
  config: {
    // Whether this hook is enabled
    enabled: boolean;
    seedValue: T;
    // Whether this client is allowed to seed the value
    seedEnabled: boolean;
    // Whether this client can write the value, including the seed, or delete
    readOnly: boolean;
    onWrite?: (value: Nullable<T, false>) => void;
    resetWhenUmount?: boolean;
  }
): readonly [Nullable<T>, (next: Nullable<T, false>) => Promise<void>] {
  const {
    enabled,
    seedValue,
    seedEnabled,
    readOnly,
    onWrite,
    resetWhenUmount,
  } = config;
  const ref = useDatabaseRef<Nullable<T, false>>(path);
  const [recvCount, incRecvCount] = useReducer((c) => ++c, 0);
  const latestOnWrite = useRef(onWrite);
  const firebaseConnected = useIsFirebaseConnected();
  const latestValue = useRef<null | T | undefined>(undefined);
  // Note(jialin), although incRecvCount can do the same thing, I don't want to
  // break the semantic of recvCount.
  const forceUpdate = useForceUpdate();
  // put seedValue into a state variable so subsequent renders of this hook do
  // not cause `initial` to change. We don't want ever user of the hook to have
  // to useMemo() unnecessarily on the seedValue. The seedValue is only used to
  // populate the database if it is empty.
  const [seed] = useState(seedValue);
  const isMounted = useMountedState();
  const seedInitAttempted = useRef(false);

  latestOnWrite.current = onWrite;

  const write = useCallback(
    async (next: Nullable<T, false>) => {
      if (!enabled || readOnly || !isMounted()) return;
      try {
        log.willOutput(LogLevel.Debug) &&
          log.debug(`Write value ${path} ${JSON.stringify(next)}`);
        if (next === null) {
          await ref.remove();
        } else {
          await ref.update(next);
        }
        latestOnWrite.current?.(next);
      } catch (err) {
        log.error(`Failed to update value at ${path}`, err);
      }
    },
    [enabled, isMounted, path, readOnly, ref]
  );

  useEffect(() => {
    if (!firebaseConnected || !enabled) return;
    ref.on('value', function firebaseValueReceiver(snap) {
      if (!isMounted()) return;
      const snapVal = snap.val();
      // Note(jialin): if there is no remote data, this will still be triggered
      // once with snap value `null`, the initial latestValue is `undefined`,
      // that's how the first time update triggered and change the recvCount to
      // non-zero.
      if (snapVal !== latestValue.current) {
        latestValue.current = snapVal;
        incRecvCount();
        log.willOutput(LogLevel.Debug) &&
          log.debug(`Recv value ${path} ${JSON.stringify(snapVal)}`);
      }
    });
  }, [enabled, firebaseConnected, isMounted, path, ref]);

  useEffect(() => {
    if (!enabled) return;
    return () => {
      ref.off('value');
      if (resetWhenUmount) {
        latestValue.current = null;
        forceUpdate();
      }
    };
  }, [enabled, ref, path, resetWhenUmount, forceUpdate]);

  useEffect(() => {
    if (
      !enabled ||
      !firebaseConnected ||
      recvCount === 0 ||
      readOnly ||
      !seedEnabled ||
      seedInitAttempted.current
    )
      return;

    // We only try init seed once
    seedInitAttempted.current = true;

    log.willOutput(LogLevel.Debug) &&
      log.debug(
        `Check seed ${path} ${JSON.stringify(
          latestValue.current
        )} ${recvCount} ${readOnly} ${seedEnabled}`
      );

    // Use the "seed" only if it does not exist yet.
    if (latestValue.current === null) {
      write(seed);
      log.willOutput(LogLevel.Debug) &&
        log.debug(`Wrote seed value ${path} ${JSON.stringify(seed)}`);
    }
  }, [
    enabled,
    firebaseConnected,
    path,
    readOnly,
    recvCount,
    seed,
    seedEnabled,
    write,
  ]);

  return [latestValue.current, write] as const;
}

export type FirebaseBatchWriter = (
  data: Record<string, unknown>
) => Promise<void>;

export function useFirebaseBatchWrite(prefixed = true): FirebaseBatchWriter {
  const { svc } = useFirebaseContext();

  return useCallback(
    async (data: Record<string, unknown>) => {
      let formattedData = uncheckedIndexAccess_UNSAFE({});
      if (prefixed) {
        for (const [key, val] of Object.entries(data)) {
          formattedData[svc.prefixedPath(key)] = val;
        }
      } else {
        formattedData = data;
      }
      try {
        const ref = svc.database().ref();
        log.debug('Batch write value', { data: formattedData });
        await ref.update(formattedData);
      } catch (error) {
        log.error('Failed to batch write value', error, {
          data: formattedData,
        });
      }
    },
    [prefixed, svc]
  );
}

export const useIsFirebaseConnected = (): boolean => {
  const ctx = useOptionalFirebaseContext();
  return (
    ctx?.status === FirebaseStatus.Connected ||
    ctx?.status === FirebaseStatus.Connecting
  );
};

export const useFirebaseStatus = (): FirebaseStatus => {
  const ctx = useFirebaseContext();
  return ctx.status;
};

export const useInitFirebase = (
  ready: boolean,
  onInited?: () => void
): void => {
  const { init, dispose, status } = useFirebaseContext();
  const initializing = status === FirebaseStatus.Initializing;
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-init-firebase-ms',
  });
  const afterInited = useLiveCallback(() => onInited?.());

  useEffect(() => {
    if (!ready) return;
    addTask(init);
    return () => addTask(dispose);
  }, [addTask, dispose, init, ready]);

  useEffect(() => {
    if (initializing) return;
    afterInited();
  }, [initializing, afterInited]);
};

export const FirebaseContextProvider = (props: {
  svc: FirebaseService;
  recoveryConfig: RecoveryConfig;
  children?: ReactNode;
}): JSX.Element => {
  const { svc, recoveryConfig } = props;
  const [status, setStatus] = useState(FirebaseStatus.None);
  const [autoRecoveryEnabled, setAutoRecoveryEnabled] = useState(true);
  const [autoRecoveryDelay, setAutoRecoveryDelay] =
    useState<AutoRecoveryDelay>(0);
  const statusRef = useLatest(status);
  const autoRecoveryEnabledRef = useLatest(autoRecoveryEnabled);
  const autoRecoveryDelayRef = useLatest(autoRecoveryDelay / 1000);
  const timerId = useRef<ReturnType<typeof setInterval>>();
  const connectedRef = useInstance(() => svc.database().ref('.info/connected'));
  const isMounted = useIsMounted();
  const emitter = useInstance(() => new Emitter<FirebaseEvents>());

  const stopConnectionRecovery = useCallback(() => {
    if (timerId.current) {
      clearInterval(timerId.current);
      timerId.current = undefined;
    }
  }, []);

  const startConnectionRecovery = useCallback(async () => {
    stopConnectionRecovery();
    if (!autoRecoveryEnabledRef.current) {
      setStatus(FirebaseStatus.Disconnected);
      return;
    }
    let attempts = 0;
    const startedAt = Date.now();
    timerId.current = setInterval(() => {
      if (attempts >= recoveryConfig.maxAttempts) {
        stopConnectionRecovery();
        setStatus(FirebaseStatus.Disconnected);
        log.info('firebase connection recovery failed');
        return;
      }
      if (Date.now() - startedAt >= autoRecoveryDelayRef.current * 1000) {
        attempts += 1;
        log.info(`firebase connection recovery, attempts: #${attempts}`);
        svc.connect();
      }
    }, recoveryConfig.intervalMs);
  }, [
    stopConnectionRecovery,
    autoRecoveryEnabledRef,
    recoveryConfig.intervalMs,
    recoveryConfig.maxAttempts,
    autoRecoveryDelayRef,
    svc,
  ]);

  const init = useCallback(async () => {
    setStatus(FirebaseStatus.Initializing);
    try {
      await svc.signIn();
    } catch (error) {
      log.error('firebase signIn error', error);
      setStatus(FirebaseStatus.Disconnected);
      return;
    }
    connectedRef.on('value', async (snap) => {
      const val = snap.val();
      if (val === true) {
        log.info('Firebase database connected');
        stopConnectionRecovery();
        if (isMounted()) setStatus(FirebaseStatus.Connected);
      } else {
        const initializing = statusRef.current === FirebaseStatus.Initializing;
        log.info(
          `Firebase database ${initializing ? 'not connected' : 'disconnected'}`
        );
        if (initializing || !isMounted()) return;
        setStatus(FirebaseStatus.Connecting);
        startConnectionRecovery();
      }
      // TODO(drew): it might make sense to add new a new event that uses the
      // same logic as the setStatus hook above, namely: checking if the
      // component is mounted, whether or not we're in an initializing status,
      // and whether we're in the grace period of retries before fully marking
      // the connection as disconnected. This particular event is 1:1 with
      // firebase itself, and we know that most interruptions are temporary.
      emitter.emit('connection-state-changed', val);
    });
  }, [
    connectedRef,
    emitter,
    isMounted,
    startConnectionRecovery,
    statusRef,
    stopConnectionRecovery,
    svc,
  ]);

  const dispose = useCallback(async () => {
    try {
      connectedRef.off();
      await svc.signOut();
    } catch (error) {
      log.error('firebase signOut error', error);
    }
  }, [connectedRef, svc]);

  useEffect(() => {
    return () => emitter.clear();
  }, [emitter]);

  const ctxValue: FirebaseContext = useMemo(
    () => ({
      svc,
      status,
      init,
      dispose,
      autoRecoveryEnabled,
      setAutoRecoveryEnabled,
      autoRecoveryDelay,
      setAutoRecoveryDelay,
      emitter,
    }),
    [
      autoRecoveryDelay,
      autoRecoveryEnabled,
      dispose,
      emitter,
      init,
      status,
      svc,
    ]
  );

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};

export class FirebaseValueHandle<T> {
  constructor(
    readonly ref: NarrowedWebDatabaseReference<FirebaseSafeRead<T>>,
    private refPath = ref.toString().substring(ref.root.toString().length - 1)
  ) {}
  on(callback: (next: FirebaseSafeRead<T> | null) => void): void {
    const p = new Promise<void>((resolve) => {
      this.ref
        .get()
        .then((snapshot) => {
          const val = snapshot.val();
          log.debug('first time fetch', { path: this.refPath, val });
          callback(val);
          resolve();
        })
        .catch((err) => {
          log.error('first time fetch err', err, { path: this.refPath });
          resolve();
        });
    });
    p.then(() => {
      this.ref.on('value', (snapshot) => {
        const val = snapshot.val();
        log.debug('value changed', { path: this.refPath, val });
        callback(val);
      });
    });
  }
  off(): void {
    this.ref.off();
  }
  async get(): Promise<FirebaseSafeRead<T> | null> {
    return (await this.ref.get()).val();
  }
  async set(val: FirebaseSafeRead<T> | null): Promise<void> {
    await this.ref.set(val);
  }
  async update(val: Partial<FirebaseSafeRead<T>>): Promise<void> {
    await this.ref.update(val);
  }
  async remove(): Promise<void> {
    await this.ref.remove();
  }
}
